import React, { useMemo } from 'react';
import clsx from 'clsx';

import { CategoryProps } from 'components/ServicesSection';
import { LazyloadBackground } from 'components/Image';
import { safeTitle } from 'components/ServicesSection/utils';
import { useBreakpoint } from 'modules/hooks/useBreakpoint';
import Button from 'components/Button';
import ServicesList from './ServicesList';

type ServicesTabPanelProps = {
  category: CategoryProps;
  handleClick: (item?: string | null) => void;
  handleKeyUp: (
    event: React.KeyboardEvent<HTMLButtonElement | HTMLLIElement>,
  ) => void;
  selectedTab: string | null;
  servicesListRef: React.MutableRefObject<HTMLDivElement | null>;
  tabPanelRef: React.MutableRefObject<HTMLDivElement | null>;
};

const ServicesTabPanel: React.FunctionComponent<ServicesTabPanelProps> = ({
  category,
  handleClick,
  handleKeyUp,
  selectedTab,
  servicesListRef,
  tabPanelRef,
}) => {
  const selectedCategory = useMemo(
    () => safeTitle(category?.title) === selectedTab,
    [category, selectedTab],
  );
  const { isBreakpoint } = useBreakpoint('(max-width: 767px)');

  const classNames = clsx('services-tab-panel', selectedCategory && 'active');

  return category?.title && category.icon ? (
    <div
      className={classNames}
      id={safeTitle(category.title)}
      ref={selectedCategory ? tabPanelRef : null}
      role="tabpanel"
      tabIndex={!isBreakpoint && selectedCategory ? 0 : -1}>
      <Button
        ariaControls={safeTitle(category.title)}
        ariaExpanded={selectedTab === safeTitle(category.title)}
        className="services-button"
        disabled={!isBreakpoint}
        label={category.title}
        onClick={() => handleClick(safeTitle(category.title))}
        onKeyUp={handleKeyUp}>
        <LazyloadBackground
          image={[{ url: category.icon }]}
          local={false}
          tag="span"
        />
      </Button>
      <ServicesList
        category={category}
        selectedCategory={selectedCategory}
        servicesListRef={servicesListRef}
      />
      <LazyloadBackground
        image={[{ url: category.icon }]}
        local={false}
        tag="div"
      />
    </div>
  ) : null;
};

export default React.memo(ServicesTabPanel);
