import React, { useMemo } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES, Block, Inline, BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';

import {
  richTextHyperlink,
  richTextLastParagraph,
  richTextListItem,
} from './utils';

import('./styles.scss');

export { richTextHyperlink } from './utils';

export type RichTextProps = {
  applyStyle?: boolean;
  content: string;
  options?: Options;
};

const RichText: React.FunctionComponent<RichTextProps> = ({
  applyStyle = false,
  content,
  options,
}) => {
  const defaultOptions = useMemo(() => {
    return {
      renderNode: {
        [INLINES.HYPERLINK]: (
          node: Block | Inline,
          children: React.ReactNode,
        ) => richTextHyperlink(node, children),
        [BLOCKS.LIST_ITEM]: (node: Block | Inline) => richTextListItem(node),
        [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) =>
          richTextLastParagraph(children),
      },
    };
  }, []);

  return (
    <React.Fragment>
      {applyStyle ? (
        <div className="rich-text-container">
          {renderRichText(
            { raw: content, references: [] },
            options || defaultOptions,
          )}
        </div>
      ) : (
        renderRichText(
          { raw: content, references: [] },
          options || defaultOptions,
        )
      )}
    </React.Fragment>
  );
};

export default React.memo(RichText);
