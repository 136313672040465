import { useCallback, useEffect, useRef, useState } from 'react';

import { CategoryProps } from '..';
import { safeTitle } from '../utils';
import { useMobileAnimation } from './useMobileAnimation';

export const useTabs = (
  categories?: readonly (CategoryProps | null)[] | null,
) => {
  const tabRef = useRef<HTMLUListElement | null>(null);
  const panelRef = useRef<HTMLDivElement | null>(null);
  const focusableItems = useRef<HTMLElement[]>([]);

  const [selectedTab, setSelectedTab] = useState<string | null>(
    (categories && safeTitle(categories[0]?.title)) || null,
  );

  const { anchorLink, servicesListRef } = useMobileAnimation(selectedTab);

  const handleClick = useCallback(
    (item?: string | null) => {
      if (item) {
        setSelectedTab(item);
        anchorLink();
      }
    },
    [anchorLink],
  );

  // Used for desktop links only
  const handleKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLButtonElement | HTMLLIElement>,
      item?: string | null,
    ) => {
      event.key === 'Enter' && item && setSelectedTab(item);
    },
    [],
  );

  // Used for desktop links only
  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement | HTMLLIElement>) => {
      const activeTab =
        tabRef.current &&
        tabRef.current.querySelector("[aria-selected='true']");
      if (
        tabRef.current &&
        activeTab &&
        window.matchMedia('(min-width: 768px)').matches
      ) {
        const tabs = tabRef.current.querySelectorAll("[role='tab']");
        const firstTab = tabs[0].getAttribute('aria-controls');
        const lastTab = tabs[tabs.length - 1].getAttribute('aria-controls');
        let nextTab = Array.from(tabs).indexOf(activeTab) + 1;
        let prevTab = Array.from(tabs).indexOf(activeTab) - 1;

        switch (event.key) {
          case 'ArrowLeft':
            event.preventDefault();
            prevTab = prevTab >= 0 ? prevTab : tabs.length - 1;
            setSelectedTab(tabs[prevTab].getAttribute('aria-controls'));
            (tabs[prevTab] as HTMLElement).focus();
            break;
          case 'ArrowRight':
            event.preventDefault();
            nextTab = nextTab && nextTab < tabs.length ? nextTab : 0;
            setSelectedTab(tabs[nextTab].getAttribute('aria-controls'));
            (tabs[nextTab] as HTMLElement).focus();
            break;
          case 'Home':
            event.preventDefault();
            setSelectedTab(firstTab);
            (tabs[0] as HTMLElement).focus();
            break;
          case 'End':
            event.preventDefault();
            setSelectedTab(lastTab);
            (tabs[tabs.length - 1] as HTMLElement).focus();
            break;
        }
      }
    },
    [tabRef],
  );

  const handleFocus = useCallback(
    (event: KeyboardEvent) => {
      if (
        panelRef.current === event.target &&
        event.key === 'Tab' &&
        window.matchMedia('(min-width: 768px)').matches
      ) {
        const panelRefIndex = Array.from(focusableItems.current).indexOf(
          panelRef.current as HTMLElement,
        );
        const activeTab =
          tabRef.current &&
          tabRef.current.querySelector("[aria-selected='true']");
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
          if (event.shiftKey) {
            (activeTab as HTMLElement).focus();
          }
          if (!event.shiftKey) {
            (focusableItems.current[panelRefIndex + 1] as HTMLElement).focus();
          }
        }
      }
    },
    [panelRef],
  );

  const updateFocusableItems = useCallback(() => {
    (focusableItems.current as unknown) = document.body.querySelectorAll(
      '[tabindex]:not([tabindex="-1"])',
    );
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(() => updateFocusableItems());
    updateFocusableItems();
    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });
    return () => {
      observer.disconnect();
    };
  }, [updateFocusableItems]);

  useEffect(() => {
    window.addEventListener('keyup', handleFocus);
    return () => {
      window.removeEventListener('keyup', handleFocus);
    };
  }, [handleFocus]);

  return {
    handleClick,
    handleKeyDown,
    handleKeyUp,
    selectedTab,
    servicesListRef,
    tabListRef: tabRef,
    tabPanelRef: panelRef,
  };
};
