import React from 'react';

import { CategoryProps } from 'components/ServicesSection';
import ServicesItem from '../ServicesItem';

type ServicesListProps = {
  category: CategoryProps;
  selectedCategory: boolean;
  servicesListRef: React.MutableRefObject<HTMLDivElement | null>;
};

const ServicesList: React.FunctionComponent<ServicesListProps> = ({
  category,
  selectedCategory,
  servicesListRef,
}) => {
  return (
    <div
      className="services-list"
      ref={selectedCategory ? servicesListRef : null}>
      <ul>
        {category.services?.slice(0, 6).map((service, key) => {
          return service?.title ? (
            <ServicesItem
              key={`${key}-${service.title}`}
              slug={service.slug}
              style={{ animationDelay: `${(key + 1) * 100}ms` }}
              title={service.title}
            />
          ) : null;
        })}
      </ul>
      {category.services && category.services.length > 6 && (
        <ul>
          {category.services
            ?.slice(6, category.services.length)
            .map((service, key) => {
              return service?.title ? (
                <ServicesItem
                  key={`${key}-${service.title}`}
                  slug={service.slug}
                  style={{ animationDelay: `${(key + 7) * 100}ms` }}
                  title={service.title}
                />
              ) : null;
            })}
        </ul>
      )}
    </div>
  );
};

export default React.memo(ServicesList);
