import { useCallback, useEffect, useState } from 'react';

export const useBreakpoint = (mediaQuery: string) => {
  const [isBreakpoint, setIsBreakpoint] = useState(false);

  const checkBreakpoint = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (window.matchMedia(`${mediaQuery}`).matches) {
        setIsBreakpoint(true);
      } else {
        setIsBreakpoint(false);
      }
    }
  }, [mediaQuery]);

  useEffect(() => {
    checkBreakpoint();
    window.addEventListener('resize', checkBreakpoint);
    return () => {
      window.removeEventListener('resize', checkBreakpoint);
    };
  }, [checkBreakpoint]);

  return {
    isBreakpoint,
  };
};
