import React from 'react';

import Arrow from 'modules/theme/images/button/arrow.svg';
import PageLink from 'components/Hyperlink/PageLink';

type ServicesItemProps = {
  slug: string | null;
  style: React.CSSProperties;
  title: string;
};

const ServicesItem: React.FunctionComponent<ServicesItemProps> = ({
  slug,
  style,
  title,
}) => {
  return slug ? (
    <li className="services-item" style={style}>
      <Arrow />
      <PageLink label={title} slug={slug} />
    </li>
  ) : (
    <li className="services-item" style={style}>
      <Arrow />
      <span>{title}</span>
    </li>
  );
};

export default React.memo(ServicesItem);
