import React from 'react';

import Hero from 'components/Hero';
import LogoList from 'components/LogoList';
import Main from 'components/Main';
import ServicesSection from 'components/ServicesSection';

type TemplateProps = Queries.WhatWeDoQuery;

const Template: React.FunctionComponent<TemplateProps> = ({
  contentfulWhatWeDo: props,
}) => {
  return (
    <Main>
      <Hero {...props?.heroReference} />
      <ServicesSection {...props?.servicesSectionReference} />
      <LogoList {...props?.logoListReference} />
    </Main>
  );
};

export default React.memo(Template);
