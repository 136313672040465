import React from 'react';
import { Block, Inline, BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import ExternalLink, {
  ExternalLinkTarget,
} from 'components/Hyperlink/ExternalLink';
import PageLink from 'components/Hyperlink/PageLink';

export const richTextHyperlink = (
  node: Block | Inline,
  children: React.ReactNode,
) => {
  const { uri } = node.data;
  if (uri.substring(0, 1) === '/') {
    return <PageLink slug={uri}>{children}</PageLink>;
  }
  return (
    <ExternalLink href={uri} target={ExternalLinkTarget.SELF}>
      {children}
    </ExternalLink>
  );
};

export const richTextListItem = (node: Block | Inline) => {
  //eslint-disable-next-line
  const transformedChildren = documentToReactComponents(node as any, {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => children,
      [BLOCKS.LIST_ITEM]: (_node, children) => children,
    },
  });
  return <li>{transformedChildren}</li>;
};

export const richTextLastParagraph = (children: React.ReactNode) => {
  const isEmptyChildren = children?.toString().trim() === '';

  if (isEmptyChildren) return null;

  return <p>{children}</p>;
};
