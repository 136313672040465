import React from 'react';

import { LazyloadBackground } from 'components/Image';
import { useBreakpoint } from 'modules/hooks/useBreakpoint';

type ServicesTabListItemProps = {
  icon: string;
  id?: string;
  handleClick: (item?: string | null) => void;
  handleKeyDown: (
    event: React.KeyboardEvent<HTMLLIElement>,
    item?: string | null,
  ) => void;
  handleKeyUp: (event: React.KeyboardEvent<HTMLLIElement>) => void;
  selectedTab: string | null;
  title: string;
};

const ServicesTabListItem: React.FunctionComponent<
  ServicesTabListItemProps
> = ({
  icon,
  id,
  handleClick,
  handleKeyDown,
  handleKeyUp,
  selectedTab,
  title,
}) => {
  const { isBreakpoint } = useBreakpoint('(max-width: 767px)');
  return (
    <li
      aria-controls={id}
      aria-selected={selectedTab === id}
      className="services-button"
      onClick={() => handleClick(id)}
      onKeyDown={event => handleKeyDown(event, id)}
      onKeyUp={handleKeyUp}
      onMouseDown={event => event.preventDefault()}
      role="tab"
      tabIndex={isBreakpoint ? -1 : selectedTab === id ? 0 : -1}>
      <LazyloadBackground image={[{ url: icon }]} local={false} tag="span" />
      <span>{title}</span>
    </li>
  );
};

export default React.memo(ServicesTabListItem);
