import React from 'react';

import { CategoryProps } from './types';
import Services from './Services';
import RichText from 'components/RichText';

import './styles.scss';

export type { CategoryProps } from './types';

type ServicesSectionProps = {
  description?: {
    raw: string | null;
  } | null;
  servicesReference?: {
    servicesList: {
      categories: readonly (CategoryProps | null)[] | null;
    } | null;
  } | null;
  title?: string | null;
};

const ServicesSection: React.FunctionComponent<ServicesSectionProps> = ({
  description,
  servicesReference,
  title,
}) => {
  return (
    <section className="services">
      <div className="container">
        {title && <h2>{title}</h2>}
        {description?.raw && <RichText content={description.raw} />}
        {servicesReference?.servicesList?.categories && (
          <Services categories={servicesReference.servicesList.categories} />
        )}
      </div>
    </section>
  );
};

export default React.memo(ServicesSection);
