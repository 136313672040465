import React from 'react';

import { CategoryProps } from '..';
import { useTabs } from '../hooks/useTabs';
import ServicesTabList from './ServicesTabList';
import ServicesTabPanel from './ServicesTabPanel';

type ServicesProps = {
  categories?: readonly (CategoryProps | null)[] | null;
};

const Services: React.FunctionComponent<ServicesProps> = ({ categories }) => {
  const { tabListRef, tabPanelRef, servicesListRef, ...props } =
    useTabs(categories);

  return (
    <div className="services-tab" id="services-tab">
      <ServicesTabList
        categories={categories}
        tabListRef={tabListRef}
        {...props}
      />
      <div>
        {categories?.map((category, key) => {
          return category ? (
            <ServicesTabPanel
              category={category}
              key={`${category?.title}-${key}`}
              servicesListRef={servicesListRef}
              tabPanelRef={tabPanelRef}
              {...props}
            />
          ) : null;
        })}
      </div>
    </div>
  );
};
export default React.memo(Services);
