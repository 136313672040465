import React from 'react';

import { Picture, PictureProps } from 'components/Image';

import './style.scss';

type HeroProps = {
  description?: {
    description?: string | null;
  } | null;
  image?: Omit<PictureProps, 'local'> | null;
  title?: string | null;
};

const Hero: React.FunctionComponent<HeroProps> = ({
  description,
  image,
  title,
}) => {
  return (
    <section className="hero">
      <Picture
        alt={image?.alt}
        lazyload={false}
        local={false}
        image={image?.image}
      />
      <div className="container">
        {title && <h1>{title}</h1>}
        {description && <p>{description?.description}</p>}
      </div>
    </section>
  );
};

export default React.memo(Hero);
