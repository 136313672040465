import React from 'react';

import LogoItem, { LogoItemProps } from './LogoItem';

import './styles.scss';

export type LogoListProps = {
  logoList?: {
    logos: readonly (LogoItemProps | null)[] | null;
  } | null;
};

const LogoList: React.FunctionComponent<LogoListProps> = ({ logoList }) => {
  return (
    <section className="logo-list">
      <h2 className="visually-hidden">Clients we have worked with</h2>
      <ul className="container">
        {logoList?.logos?.map((logo, key) =>
          logo?.alt && logo?.logo ? (
            <LogoItem
              alt={logo?.alt}
              key={`${logo?.alt}-${key}`}
              logo={logo?.logo}
            />
          ) : null,
        )}
      </ul>
    </section>
  );
};

export default React.memo(LogoList);
