import React from 'react';

import { CategoryProps } from 'components/ServicesSection';
import { safeTitle } from 'components/ServicesSection/utils';
import ServicesTabListItem from './ServicesTabListItem';

type ServicesTabListProps = {
  categories?: readonly (CategoryProps | null)[] | null;
  handleClick: (item?: string | null) => void;
  handleKeyDown: (
    event: React.KeyboardEvent<HTMLLIElement>,
    item?: string | null,
  ) => void;
  handleKeyUp: (event: React.KeyboardEvent<HTMLLIElement>) => void;
  selectedTab: string | null;
  tabListRef: React.MutableRefObject<HTMLUListElement | null>;
};

const ServicesTabList: React.FunctionComponent<ServicesTabListProps> = ({
  categories,
  tabListRef,
  ...props
}) => {
  return categories && categories.length > 0 ? (
    <ul
      aria-controls="services-tab"
      className="services-tab-list"
      ref={tabListRef}
      role="tablist">
      {categories.map((category, key) => {
        return category?.title && category.icon ? (
          <ServicesTabListItem
            id={safeTitle(category.title)}
            icon={category.icon}
            key={`tab-${safeTitle(category.title)}-${key}`}
            title={category.title}
            {...props}
          />
        ) : null;
      })}
    </ul>
  ) : null;
};

export default React.memo(ServicesTabList);
