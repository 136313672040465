import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout, { ILayout } from 'modules/layout';
import Meta from 'modules/meta';
import Template from './Template';

export const query = graphql`
  query WhatWeDo {
    contentfulSiteConfig {
      ...siteConfig
    }
    contentfulWhatWeDo {
      ...heroWhatWeDo
      ...logoListWhatWeDo
      ...metaWhatWeDo
      ...servicesSectionWhatWeDo
    }
  }
`;

const Page: React.FunctionComponent<PageProps<Queries.WhatWeDoQuery>> &
  ILayout = ({ data }) => {
  return <Template {...data} />;
};

Page.Layout = Layout;
export default Page;

export const Head: React.FunctionComponent<
  PageProps<Queries.WhatWeDoQuery>
> = ({ data, location }) => {
  return (
    <Meta
      defaultMeta={data.contentfulSiteConfig?.defaultMeta}
      meta={data.contentfulWhatWeDo?.meta}
      pageTitle="What We Do"
      pathname={location.pathname}
    />
  );
};
