import { useCallback, useEffect, useRef } from 'react';

export const useMobileAnimation = (selectedTab: string | null) => {
  const servicesListRef = useRef<HTMLDivElement | null>(null);

  const anchorLink = useCallback(() => {
    if (
      servicesListRef.current &&
      window.matchMedia('(max-width: 767px)').matches
    ) {
      const transitionDuration = window
        .getComputedStyle(servicesListRef.current)
        .getPropertyValue('transition-duration');
      setTimeout(() => {
        servicesListRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, parseFloat(transitionDuration) * 1000);
    }
  }, []);

  const mobileAccordionAnimation = useCallback(() => {
    if (servicesListRef.current) {
      const height = servicesListRef.current.scrollHeight;
      const open = true;
      servicesListRef.current.style.height = `${open ? 0 : height}px`;
      requestAnimationFrame(() => {
        if (servicesListRef.current) {
          servicesListRef.current.style.height = `${open ? height : 0}px`;
        }
      });
    }
  }, []);

  const handleTransitionEnd = useCallback((event: TransitionEvent) => {
    if (event.target === servicesListRef.current) {
      servicesListRef.current?.style.removeProperty('height');
    }
  }, []);

  useEffect(() => {
    if (selectedTab && window.matchMedia('(max-width: 767px)').matches) {
      mobileAccordionAnimation();
    }
  }, [mobileAccordionAnimation, selectedTab]);

  useEffect(() => {
    window.addEventListener('transitionend', handleTransitionEnd);
    return () => {
      window.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [handleTransitionEnd]);

  return {
    anchorLink,
    servicesListRef,
  };
};
